import { Button as AntdButton } from 'antd'
import styled from '@emotion/styled'

export const Button = styled(AntdButton)`
  margin-bottom: 13px;
  line-height: ${props => {
    switch (props.size) {
      case 'large':
        return '60px'
      case 'small':
        return '50px'
      default:
        return '40px'
    }
  }};
  width: ${props => props.width || '100%'};
  box-sizing: border-box;
  font-size: ${props => {
    switch (props.size) {
      case 'large':
        return '16px'
      case 'small':
        return '7px'
      default:
        return '12px'
    }
  }};
  font-weight: bold;
  letter-spacing: 2.1px;
  text-transform: ${props => (props.noTextTransform ? 'none' : 'uppercase')};
  height: auto;
  
  &,
  &:hover,
  &:active,
  &:focus {
    color: ${props => (props.primary === 'true' ? '#021473' : 'white')};
    background: ${props => (props.primary === 'true' ? 'white' : 'none')};
    border: ${props =>
      props.noBorder ? 'none' : 'solid 1px rgba(255, 255, 255, 0.4)'};
  }
`
