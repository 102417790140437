import React, { useState, useEffect, useMemo } from 'react'
import { css } from 'emotion'

// import { loginPageCanvas } from './canvas'
// import uikit from '../../uikit'

import { IndexState } from './IndexState'
import { LoginState } from './LoginState'
// import { RegisterState } from './RegisterState'
// import { ConnectSocialState } from './ConnectSocialState'
import { ForgotPasswordState } from './ForgotPasswordState'
import { getToken } from './token'
import { unauthenticatedRestClient } from './restClient'
import backgroundImage from './images/bg.png'
import backButtonImage from './images/back.png'

export const BackButton = props => (
  <div
    className={css`
      position: relative;
      display: flex;
      align-items: center;
      padding: 20px;
      font-size: 18px;
      font-weight: bold;
      color: white;
      transition: opacity 0.2s;
      opacity: ${props.opacity};
    `}
    onClick={props.onClick}
  >
    <img
      alt="back button"
      src={backButtonImage}
      height={28}
      style={{ opacity: 0.75 }}
    />
    <span style={{ marginLeft: 20 }}>{props.children}</span>
  </div>
)

const getUserSocialInfo = async accessToken => {
  const query = `
    query getSocialProfile($token: String!) {
      getSocialProfile(token: $token) {
        id
        name
        nickname
        email
      }
    }
  `
  const body = {
    operationName: null,
    variables: { token: accessToken },
    query,
  }
  const { data } = await unauthenticatedRestClient.post('/', body)
  if (data.error) {
    console.error('getUserSocialInfo', data)
    return null
  }
  return data
}

// const LOGIN_PAGE_BG_ID = 'login-page-bg'

const STATE = {
  INDEX: 'index',
  LOGIN: 'login',
  REGISTER: 'register',
  CONNECT_SOCIAL: 'connect_social',
  FORGOT_PASSWORD: 'forgot_password',
}

const PREV_STATE = {
  [STATE.INDEX]: null,
  [STATE.LOGIN]: null,
  [STATE.REGISTER]: STATE.INDEX,
  [STATE.CONNECT_SOCIAL]: STATE.REGISTER,
  [STATE.FORGOT_PASSWORD]: STATE.LOGIN,
}

const LoginPageContent = ({
  dive,
  updatePageState,
  state,
  isSocialLogin,
  socialInfo,
}) => {
  console.log(state)
  return (
    <>
      {PREV_STATE[state] && (
        <BackButton
          onClick={() => {
            updatePageState(PREV_STATE[state])
          }}
        >
          Back
        </BackButton>
      )}
      <IndexState
        visible={state === STATE.INDEX}
        onLoginButtonClick={() => {
          console.log('click login')
          updatePageState(STATE.LOGIN)
        }}
        onRegisterButtonClick={() => {
          updatePageState(STATE.REGISTER)
        }}
      />
      <LoginState
        visible={state === STATE.LOGIN}
        onForgotPasswordButtonClick={() => {
          updatePageState(STATE.FORGOT_PASSWORD)
        }}
      />
      {/* <RegisterState
        visible={state === STATE.REGISTER}
        onNextButtonClick={() => {
          updatePageState(STATE.CONNECT_SOCIAL)
        }}
        onRegister={() => {
          updatePageState(STATE.LOGIN)
        }}
        isSocialLogin={isSocialLogin}
        socialInfo={socialInfo}
      /> */}
      {/* <ConnectSocialState
        visible={state === STATE.CONNECT_SOCIAL}
        onRegister={() => {
          updatePageState(STATE.LOGIN)
        }}
      /> */}
      <ForgotPasswordState
        visible={state === STATE.FORGOT_PASSWORD}
        onPasswordReset={() => {
          updatePageState(STATE.LOGIN)
        }}
      />
    </>
  )
}

const getDefaultStates = () => {
  if (window.location.search.includes('state=register')) {
    return {
      DEFAULT_IS_SOCIAL_LOGIN: true,
      DEFAULT_PAGE_STATE: STATE.REGISTER,
    }
  }
  return {
    DEFAULT_IS_SOCIAL_LOGIN: false,
    DEFAULT_PAGE_STATE: STATE.LOGIN,
  }
}

const LoginPage = () => {
  const { DEFAULT_IS_SOCIAL_LOGIN, DEFAULT_PAGE_STATE } = getDefaultStates()
  //   const [{ app, dive }, setAnimation] = useState({})
  const [state, setState] = useState(DEFAULT_PAGE_STATE)
  console.log('[state]', state)
  // const [isSocialLogin, setIsSocialLogin] = useState(DEFAULT_IS_SOCIAL_LOGIN)
  const [socialInfo, setSocialInfo] = useState({
    name: '',
    email: '',
    displayName: '',
  })

  const updatePageState = state => {
    setState(state)
    // if (dive) dive({ level: STATE_LEVEL[state] })
  }

  useEffect(() => {
    // load here
    const token = getToken()
    if (token) {
      getUserSocialInfo(getToken()).then(result => {
        console.log('getUserSocialInfo', result)
        setSocialInfo(result.data.getSocialProfile)
      })
    }
    // if (!app) {
    //   setAnimation(initBackground(LOGIN_PAGE_BG_ID))
    // }

    return () => {
      //   if (app) app.destroy(true)
    }
  }, [])

  /* const background = useMemo(
    () => (
      <div
        className={css`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          overflow: hidden;
          // TODO: handle background
          background-color: black;
        `}
        id={LOGIN_PAGE_BG_ID}
        style={{ zIndex: -1 }}
      />
    ),
    [],
  ) */
  const content = useMemo(
    () => (
      <LoginPageContent
        state={state}
        updatePageState={updatePageState}
        dive={() => {}}
        isSocialLogin={DEFAULT_IS_SOCIAL_LOGIN}
        socialInfo={socialInfo}
      />
    ),
    [socialInfo, state, DEFAULT_IS_SOCIAL_LOGIN],
  )

  return (
    <div
      className={css`
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: env(safe-area-inset-top) env(safe-area-inset-right)
          env(safe-area-inset-bottom) env(safe-area-inset-left);
      `}
    >
      <img
        alt="background"
        src={backgroundImage}
        className={css`
          position: absolute;
          width: 100%;
          height: auto;
          z-index: -1;
          bottom: 0;
          overflow: hidden;
        `}
      />
      {content}
    </div>
  )
}

export default LoginPage
