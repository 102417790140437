import React, { useState } from 'react'
import { message } from 'antd'
import { css } from 'emotion'

import { Button } from './Button'
import { Input } from './Input'

import State from './State'
// import { changePassword } from '../../common/webauth'
const changePassword = async () => {}

export const ForgotPasswordState = ({ visible, onPasswordReset }) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  return (
    <State visible={visible} hiddenPosition={State.HIDDEN_POSITION.TOP}>
      <form
        className={css`
          width: 100%;
          font-size: 12px;
          font-weight: bold;
          height: auto;
        `}
        onSubmit={async e => {
          e.preventDefault()
          if (loading) {
            return
          }
          try {
            setLoading(true)
            await changePassword(email)
            message.success(
              'Password reset instruction has been sent to your email address',
            )
            onPasswordReset()
            setEmail('')
          } catch (err) {
            setLoading(false)
            message.error(err.message)
          }
        }}
      >
        <Input
          id="email"
          label="E-mail"
          x
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Button htmlType="submit" size="large" loading={loading}>
          Reset Password
        </Button>
      </form>
    </State>
  )
}
