import React from 'react'
import { css } from 'emotion'

export const Input = ({
  id,
  label,
  type,
  placeholder,
  value,
  onChange,
  isDisabled,
}) => (
  <React.Fragment>
    <label
      for={id}
      className={css`
        margin-top: 40px;
        letter-spacing: 3.6px;
        text-transform: uppercase;
      `}
    >
      {label}
    </label>
    <input
      id={id}
      className={css`
        width: 100%;
        line-height: 40px;
        letter-spacing: 1px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.4) !important;
        margin-bottom: 40px !important;
      `}
      type={type}
      name={id}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e)}
      disabled={isDisabled}
    />
  </React.Fragment>
)
