import React from 'react'

import State from './State'
import { Button } from './Button'

export const IndexState = ({
  visible,
  onLoginButtonClick,
  onRegisterButtonClick,
}) => (
  <State visible={visible} hiddenPosition={State.HIDDEN_POSITION.TOP}>
    <Button size="large" onClick={onLoginButtonClick}>
      Login
    </Button>
  </State>
)
