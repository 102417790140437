import axios from 'axios'
import { getBackendUrl } from './config/backend-url'

const createRestClient = getToken => {
  const _client = axios.create({
    baseURL: getBackendUrl(),
  })
  const getHeaders = () => {
    const headers = getToken ? { Authorization: `Bearer ${getToken()}` } : {}
    console.log('[headers]', headers)
    return headers
  }
  return {
    post: (path, body) => _client.post(path, body, { headers: getHeaders() }),
  }
}
export const unauthenticatedRestClient = createRestClient()

export const authenticatedRestClient = createRestClient(() =>
  localStorage.getItem('token'),
)
