import React from 'react'
import ReactDOM from 'react-dom'
import history from './history'
import { Router, Route, Redirect } from 'react-router-dom'
import './index.css'
// import App from './App'
import LoginCallback from './LoginCallback'
import Stripe from './Stripe'
import Login from './Login'
import * as serviceWorker from './serviceWorker'
import './Global.css'
import { SettingPage } from './config/SettingPage.js'
import { Starter } from './Starter.js'

ReactDOM.render(
  <Starter>
    <Router history={history}>
      <div>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to="/payment" />
          }}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/login/callback" component={LoginCallback} />
        <Route exact path="/logout/callback" component={LoginCallback} />
        <Route exact path="/payment" component={Stripe} />
        <Route exact path="/setting" component={SettingPage} />
      </div>
    </Router>
  </Starter>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
