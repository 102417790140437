import { Component } from 'react'
import qs from 'qs'
import { getCallbackUrl } from './util'
import { getWebAuth } from './webAuth'
import { saveToken } from './token.js'

class LoginCallback extends Component {
  handleRedirect = token => {
    const callbackUrl = getCallbackUrl()
    const queryString = qs.stringify({ token })
    const redirectUrl = `/${callbackUrl}?${queryString}`
    console.log('[redirectUrl]', redirectUrl)
    window.location.href = redirectUrl
  }
  componentDidMount() {
    const webAuth = getWebAuth()
    webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err) {
        return console.log(err)
      }

      // The contents of authResult depend on which authentication parameters were used.
      // It can include the following:
      // authResult.accessToken - access token for the API specified by `audience`
      // authResult.expiresIn - string with the access token's expiration time in seconds
      // authResult.idToken - ID token JWT containing user profile information

      // auth0.client.userInfo(authResult.accessToken, function(err, user) {
      //   // Now you have the user's information
      // });
      // alert(JSON.stringify(authResult, null, 2))
      const token = authResult.accessToken
      saveToken(token)
      this.handleRedirect(token)
    })
  }
  render() {
    return null
  }
}

export default LoginCallback
