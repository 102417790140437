import React, { useState } from 'react'
import { message, Icon } from 'antd'
import { css } from 'emotion'
// import { AuthContext } from '../../common/AuthContext.js'
// import { processRedirectAfterAuth } from '../../common/redirectAuth.js'
// import { getRedirectPathAfterAuth } from '../../common/redirectAuth.js'
import history from './history'
import { Button } from './Button'
import { Input } from './Input'

import State from './State'
import {
  signInEmailPassword,
  signInTwitter,
  signInFacebook,
  signInGoogle,
  signInInstagram,
} from './webAuth.js'
import styled from '@emotion/styled'

const SocialButton = styled(Button)`
  > span,
  > i {
    vertical-align: middle;
  }
  > i {
    margin-right: 0.3em;
    font-size: 15px;
  }
`
const ForgotPasswordWrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2.1px;
  cursor: pointer;
  opacity: 0.75;
`

export const LoginState = ({ visible, onForgotPasswordButtonClick }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  //   return (
  // <AuthContext.Consumer>
  //   {({ onAuthChanged }) => {
  return (
    <State visible={visible} hiddenPosition={State.HIDDEN_POSITION.BOTTOM}>
      <form
        className={css`
          width: 100%;
          height: auto;
          font-size: 12px;
          font-weight: bold;
        `}
        onSubmit={async e => {
          e.preventDefault()
          if (loading) {
            return
          }
          try {
            setLoading(true)
            const result = await signInEmailPassword(email, password)
            console.log('[signIn-result]', result)
            history.push('/payment')

            // processRedirectAfterAuth()

            // updatePageState({ state: STATE.INDEX })
            // [HIGH_PRIORITY]
            // TODO: update path navigation
          } catch (err) {
            setLoading(false)
            message.error(err.message)
            console.error(err)
          }
        }}
      >
        <Input
          id="email"
          label="E-mail"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          id="password"
          label="Password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button
          className={css`
            margin-bottom: 50px;
          `}
          htmlType="submit"
          loading={loading}
          primary="true"
        >
          Login
        </Button>
      </form>
      <ForgotPasswordWrapper onClick={onForgotPasswordButtonClick}>
        Forgot password?
      </ForgotPasswordWrapper>
      <div
        className={css`
          margin-top: 40px;
        `}
      >
        <SocialButton
          onClick={() => {
            signInGoogle()
          }}
        >
          <Icon type="google" />
          Login with Google
        </SocialButton>
        <SocialButton
          onClick={() => {
            signInFacebook()
          }}
        >
          <Icon type="facebook" theme="filled" />
          Login with Facebook
        </SocialButton>
        <SocialButton
          onClick={() => {
            signInTwitter()
          }}
        >
          <Icon type="twitter" />
          Login with Twitter
        </SocialButton>
        <SocialButton
          onClick={() => {
            signInInstagram()
          }}
        >
          <Icon type="instagram" />
          Login with Instagram
        </SocialButton>
      </div>
    </State>
  )
  //       }}
  //     </AuthContext.Consumer>
  //   )
}
