import * as Sentry from '@sentry/browser'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { getToken } from './token'
import getBackendUrl from './config/backend-url'

const httpLink = new HttpLink({
  uri: getBackendUrl(),
})

const authMiddleware = setContext(async operation => {
  const token = getToken()
  if (token) {
    return {
      // Make sure to actually set the headers here
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  }
  return {
    headers: {},
  }
})

const errorLink = onError(error => {
  console.log('errorLink', error)
  const { graphQLErrors, networkError } = error
  if (graphQLErrors) {
    // do something with graphql error
    console.log('graphQLErrors', graphQLErrors)
    Sentry.captureException(graphQLErrors)
  }

  if (networkError) {
    // do something with network error
    console.log('networkError', networkError)
    Sentry.captureException(networkError)
  }
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authMiddleware, httpLink, errorLink]),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export const unauthClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([httpLink, errorLink]),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export default client
