import _ from 'lodash'
import React, { Component } from 'react'
import { message } from 'antd'
import { getAppConfig } from './config/app-config'
import history from './history'
import {
  Elements,
  StripeProvider,
  CardElement,
  injectStripe,
} from 'react-stripe-elements'
import './Stripe.css'
import { css } from 'emotion'
import gql from 'graphql-tag'
import backgroundImage from './images/bg.png'
import { client } from './apollo-client.js'
// import CheckoutForm from './CheckoutForm'
import { PlanProCard, PlanStandardCard, PlanPlusCard } from './PlanCard'
import { Loading } from './Loading'

const ENSURE_SUBSCRIPTION = gql`
  mutation ensureSubscription($planId: String!, $sourceId: String) {
    ensureSubscription(planId: $planId, sourceId: $sourceId) {
      subscriptionID
      customerID
      memberTier {
        name
      }
      startAt
      expireAt
      autoRenew
      pending
      card_brand
      card_exp_month
      card_exp_year
      card_last4
    }
  }
`

const submitPlanAndSource = async (source, planName, onSuccess, onError) => {
  console.log({ source })
  const { data: planData } = await client.query({
    query: gql`
      query plans {
        plans {
          planId
          nickname
        }
      }
    `,
  })
  const { planId } = _.find(planData.plans, p => p.nickname === planName)
  const { errors: ensureSubErrors, data: ensureSubData } = await client.mutate({
    mutation: ENSURE_SUBSCRIPTION,
    fetchPolicy: 'no-cache',
    variables: _.pickBy({
      planId,
      sourceId: source && source.id,
    }),
  })

  if (ensureSubErrors) {
    const errMsg = ensureSubErrors.join(' | ')
    console.log('[eee]', ensureSubErrors)
    onError(errMsg)
    return { status: SUBMIT_RESULT.FAIL }
  }
  const result = ensureSubData.ensureSubscription
  if (planName === _.get(result, 'memberTier.name')) {
    const { card_brand, card_exp_month, card_exp_year, card_last4 } = result
    const card = {
      brand: card_brand,
      exp_month: card_exp_month,
      exp_year: card_exp_year,
      last4: card_last4,
    }
    onSuccess(planName, card)
    return { status: SUBMIT_RESULT.SUCCESS, card }
  }
  onError('invalid plan name')
  return { status: SUBMIT_RESULT.FAIL }
}

const cardActionStyle = css`
  background-color: #ffffff;
  color: #021473;
  margin: auto;
  display: block;
  &[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`

const PLANS = {
  STANDARD: 'Standard',
  PLUS: 'Plus',
  PRO: 'Pro',
  FREE: 'Free',
}
const SUBMIT_RESULT = {
  SUCCESS: 'success',
  FAIL: 'fail',
  CARD_ERR: 'card_error',
}
class _SelectPlan extends Component {
  constructor(props) {
    console.log('[props]', props)
    super(props)
    this.submit = this.submit.bind(this)
    const { card, planName } = props
    this.state = {
      card,
      planName,
    }
  }
  // TODO: Check Duplication
  async submit(ev) {
    let source
    if (!this.state.card) {
      const sourceRes = await this.props.stripe.createSource({ type: 'card' })
      if (sourceRes.error) {
        return { status: SUBMIT_RESULT.CARD_ERR, error: sourceRes.error }
      }
      source = sourceRes.source
      if (!source) {
        return {
          status: SUBMIT_RESULT.CARD_ERR,
          error: 'failed to create source',
        }
      }
    }
    const planName = this.state.planName
    const onSuccess = this.props.onSuccess
    const onError = this.props.onError
    return await submitPlanAndSource(source, planName, onSuccess, onError)
  }

  render() {
    const { planName } = this.state
    return (
      <div
        alt="Select Plan"
        className={css`
          width: 80%;
          margin: auto;
        `}
      >
        <Header>Select Your Plan</Header>
        <div
          alt="Plan Cards"
          className={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          <PlanStandardCard
            onClick={() => this.setState({ planName: PLANS.STANDARD })}
            actionText="Select Plan"
            active={planName === PLANS.STANDARD}
          />
          <PlanPlusCard
            onClick={() => this.setState({ planName: PLANS.PLUS })}
            actionText="Select Plan"
            active={planName === PLANS.PLUS}
          />
          <PlanProCard
            onClick={() => this.setState({ planName: PLANS.PRO })}
            actionText="Select Plan"
            active={planName === PLANS.PRO}
          />
        </div>
        <div
          alt="Payment Section"
          className={css`
            max-width: 500px;
            margin: auto;
          `}
        >
          <PaymentInfo
            enterEditingCard={() => this.setState({ card: null })}
            card={this.state.card}
          />
          <button
            className={cardActionStyle}
            onClick={async e => {
              const { status, card, error } = await this.submit(e)
              switch (status) {
                case SUBMIT_RESULT.SUCCESS:
                  this.setState({ card })
                  break
                case SUBMIT_RESULT.CARD_ERR:
                  this.props.onError(error.message)
                  break
                default:
                  break
              }
              if (status === 'success') {
                this.setState({ card })
              }
              // updateCard(null)
            }}
            disabled={!planName}
          >
            {planName ? `Subscribe to ${planName} plan` : 'subscribe'}
          </button>
        </div>
      </div>
    )
  }
}
const SelectPlan = injectStripe(_SelectPlan)

const Header = ({ children }) => (
  <h1
    className={css`
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: -0.5px;
      text-align: center;
      color: #ffffff;
    `}
  >
    {children}
  </h1>
)

const PaymentInfo = ({ card, enterEditingCard, onSubmit }) => (
  <div alt="Payment Info">
    <h3
      className={css`
        margin-top: 40px;
        font-size: 14px;
        font-weight: bold;
        opacity: 0.5;
        color: #fff;
      `}
    >
      PAYMENT INFORMATION
    </h3>
    {!card && (
      <>
        <CardElement hidePostalCode />
        {onSubmit && (
          <button
            className={cardActionStyle}
            onClick={e => {
              if (onSubmit) {
                onSubmit(e)
              }
              // updateCard(null)
            }}
          >
            SAVE
          </button>
        )}
      </>
    )}
    {card && (
      <>
        <div
          className={css`
            color: white;
            font-weight: bold;
          `}
        >
          {/* {'VISA 6591 09/23 ***'} */}
          {`${card.brand} ${card.last4} ${card.exp_month}/${card.exp_year} ***`}
          <span>
            <button
              className={`${cardActionStyle} ${css`
                margin-left: 30px;
                width: 56px;
                font-size: 12px;
                text-align: center;
              `}`}
              onClick={() => {
                enterEditingCard()
              }}
            >
              Edit
            </button>
          </span>
        </div>
      </>
    )}
  </div>
)

class _PlanInfo extends Component {
  constructor(props) {
    console.log('[props]', props)
    super(props)
    const { card, planName } = props
    this.state = {
      card,
      planName,
    }
  }

  // TODO: Check Duplication
  async submit(ev) {
    let source
    if (!this.state.card) {
      const sourceRes = await this.props.stripe.createSource({ type: 'card' })
      if (sourceRes.error) {
        return { status: SUBMIT_RESULT.CARD_ERR, error: sourceRes.error }
      }
      source = sourceRes.source
      if (!source) {
        return {
          status: SUBMIT_RESULT.CARD_ERR,
          error: 'failed to create source',
        }
      }
    }
    const planName = this.state.planName
    const onSuccess = this.props.onSuccess
    const onError = this.props.onError
    return await submitPlanAndSource(source, planName, onSuccess, onError)
  }
  render() {
    const { planName } = this.state
    return (
      <div
        alt="plan info"
        className={css`
          padding: 0 80px;
        `}
      >
        <Header>Your Current Plan</Header>
        {planName === PLANS.PRO && (
          <PlanProCard
            onClick={() => this.props.updatePageState(STATES.SELECT_PLAN)}
            actionText="Change Plan"
          />
        )}
        {planName === PLANS.PLUS && (
          <PlanPlusCard
            onClick={() => this.props.updatePageState(STATES.SELECT_PLAN)}
            actionText="Change Plan"
          />
        )}
        {planName === PLANS.STANDARD && (
          <PlanStandardCard
            onClick={() => this.props.updatePageState(STATES.SELECT_PLAN)}
            actionText="Change Plan"
          />
        )}
        <PaymentInfo
          card={this.state.card}
          enterEditingCard={() => this.setState({ card: null })}
          onSubmit={async e => {
            const { status, card } = await this.submit(e)
            if (status === 'success') {
              this.setState({ card })
            }
          }}
        />
      </div>
    )
  }
}
const PlanInfo = injectStripe(_PlanInfo)

const QUERY_ME = gql`
  query me {
    me {
      name
      email
      memberTier {
        name
      }
      subscription {
        memberTier {
          name
        }
        startAt
        expireAt
        autoRenew
        pending
        pendingMemberTier {
          name
        }
        card_brand
        card_exp_month
        card_exp_year
        card_last4
      }
    }
  }
`

const STATES = {
  PLAN_INFO: 'PLAN_INFO',
  SELECT_PLAN: 'SELECT_PLAN',
}
// const DEFAULT_PAGE_STATE = STATES.SELECT_PLAN
const DEFAULT_PAGE_STATE = STATES.PLAN_INFO
class Payment extends Component {
  state = {
    page_state: DEFAULT_PAGE_STATE,
    planName: null,
    card: null,
    loading: true,
  }
  componentDidMount() {
    this.setState({
      page_state: DEFAULT_PAGE_STATE,
      loading: true,
    })
    client
      .query({
        query: QUERY_ME,
        fetchPolicy: 'network-only',
      })
      .then(({ loading, error, data }) => {
        if (error) {
          throw error
        }
        if (!loading && !error && data) {
          const user = data.me
          const { subscription } = user
          if (!subscription) {
            this.setState({
              page_state: STATES.SELECT_PLAN,
              loading: false,
            })
          } else {
            const {
              card_brand,
              card_exp_month,
              card_exp_year,
              card_last4,
            } = subscription
            const card = card_brand && {
              brand: card_brand,
              exp_month: card_exp_month,
              exp_year: card_exp_year,
              last4: card_last4,
            }
            this.setState({
              // TODO: check card
              page_state: STATES.PLAN_INFO,
              loading: false,
              planName: _.get(subscription, 'memberTier.name'),
              card,
            })
          }
        }
      })
      .catch(e => {
        history.push('/login')
        console.log('[e]', e)
      })
  }
  updatePageState(page_state) {
    this.setState({ page_state })
  }
  renderState() {
    const { page_state } = this.state
    if (page_state === STATES.PLAN_INFO) {
      return (
        <Elements>
          <PlanInfo
            planName={this.state.planName}
            card={this.state.card}
            updatePageState={p => this.updatePageState(p)}
            onSuccess={(__, card) => {
              this.setState({ card })
              message.success('updated card succesful')
            }}
            onError={msg => message.error(`error : ${msg}`)}
          />
        </Elements>
      )
    }
    return (
      <Elements>
        <SelectPlan
          planName={this.state.planName}
          card={this.state.card}
          updatePageState={p => this.updatePageState(p)}
          onSuccess={(planName, card) => {
            this.setState({ planName, card })
            this.updatePageState(STATES.PLAN_INFO)
            message.success('updated plan succesful')
          }}
          onError={msg => message.error(`error : ${msg}`)}
        />
      </Elements>
    )
  }
  render() {
    if (this.state.loading) {
      return <Loading />
    }
    return (
      <StripeProvider apiKey={getAppConfig().stripe.publishablekey}>
        <div
          className={css`
            position: absolute;
            width: 100%;
            height: 100%;
            // overflow: hidden;
            padding: env(safe-area-inset-top) env(safe-area-inset-right)
              env(safe-area-inset-bottom) env(safe-area-inset-left);
          `}
        >
          <div
            alt="background"
            src={backgroundImage}
            className={css`
              position: fixed;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-image: url(/static/media/bg.b8463383.png);
              background-size: cover;
              background-repeat: no-repeat;
              background-position: 50% 100%;
            `}
          />
          <div
            className={css`
              position: relative;
              padding-top: 50px;
              padding-bottom: 50px;
            `}
          >
            {this.renderState()}
          </div>
        </div>
      </StripeProvider>
    )
  }
}
export default Payment
