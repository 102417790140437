import auth0 from 'auth0-js'
import { getAppConfig } from './config/app-config'
import { saveToken, clearToken } from './token'
import request from 'request'

let _webAuth = null

export const getWebAuth = () => {
  if (!_webAuth) {
    _webAuth = new auth0.WebAuth(getAppConfig().auth0)
  }
  return _webAuth
}

const origin = window.location.origin
const redirectUri = `${origin}/login/callback?callback=payment`

export const signInEmailPassword = async (email, password) => {
  try {
    await new Promise((resolve, reject) => {
      const config = getAppConfig()
      const options = {
        method: 'POST',
        url: `https://${config.auth0.domain}/oauth/token`,
        headers: { 'content-type': 'application/json' },
        body: {
          grant_type: 'password',
          username: email,
          password: password,
          audience: config.auth0.audience,
          scope: 'openid read:current_user',
          client_id: config.auth0.clientID,
          client_secret: config.auth0.clientSecret,
        },
        json: true,
      }

      request(options, (error, _, body) => {
        if (error) {
          reject(new Error(error))
          return
        }
        const { access_token } = body

        if (!access_token) {
          reject(new Error(body.error_description))
          return
        }

        saveToken(access_token)
        resolve(access_token)
      })
    })
  } catch (err) {
    console.log('[signIn-err]', err)
    clearToken()
  }
}
export const signInTwitter = () => {
  const webAuth = getWebAuth()
  webAuth.authorize({
    connection: 'twitter',
    redirectUri,
  })
}
export const signInInstagram = () => {
  const webAuth = getWebAuth()
  webAuth.authorize({
    connection: 'instagram',
    redirectUri,
  })
}

export const signInFacebook = () => {
  const webAuth = getWebAuth()
  webAuth.authorize({
    connection: 'facebook',
    redirectUri,
    connection_scope: 'manage_pages,read_insights,instagram_manage_insights',
  })
}
export const signInGoogle = () => {
  const webAuth = getWebAuth()
  webAuth.authorize({
    connection: 'google-oauth2',
    responseType: 'token',
    redirectUri,
    connection_scope:
      'https://www.googleapis.com/auth/youtube.readonly,https://www.googleapis.com/auth/yt-analytics.readonly',
  })
}
