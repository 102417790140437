import React from 'react'
import { css } from 'emotion'
import backgroundImage from './images/bg.png'
import { Spin } from 'antd'

export const Loading = () => (
  <div
    className={css`
      position: absolute;
      width: 100%;
      height: 100%;
    `}
  >
    <img
      alt="background"
      src={backgroundImage}
      className={css`
        position: fixed;
        width: 100%;
        height: auto;
        z-index: -1;
        bottom: 0;
        overflow: hidden;
      `}
    />
    <Spin
      size="large"
      className={css`
        position: fixed !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .ant-spin-dot.ant-spin-dot-spin {
          width: 60px;
          height: 60px;
          font-size: 60px;
          .ant-spin-dot-item {
            background-color: white;
            transform: scale(1.5);
          }
        }
      `}
    />
  </div>
)
