import React from 'react'
import { Icon, Tag } from 'antd'
import { css } from 'emotion'

const PLUS_FEATURES = [
  'Enhanced Artist Profile',
  'Trensetterr Spotlight',
  'Unlimited Song Uploads',
  '4 Songs Backed Limit',
  'Social Media Analytics (1 Platform)',
  'Free Distribution of Backed Song',
  'Song Performance Stats',
]

const PRO_FEATURES = [
  'Enhanced Artist Profile',
  'Trensetterr Spotlight',
  'Unlimited Song Uploads',
  'Unlimited Backed Songs',
  'Publishing Rev Share Increase',
  'Social Media Analytics (All Platforms)',
  'Free Distribution of Backed Song',
  'Song Performance Stats',
]

const STANDARD_FEATURES = [
  'Uploaded 4 Songs',
  '2 Songs Backed Limit',
  'Free Distribution of Backed Song',
  'Song Performance Stats',
]

export const PlanCard = ({
  features,
  title,
  backgroundStyle,
  fontColor,
  actionText,
  onClick,
  active,
  price,
}) => (
  <div
    className={css`
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 15px;
      padding: 3px;
      border: solid 2px ${active ? 'white' : 'transparent'};
      border-radius: 19px;
    `}
  >
    <div
      className={css`
        // HACK what did you hack?
        max-width: 22rem;
        margin: 0 auto;
        // height: 212.5px;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        // box-shadow: 7.5px 1px 7.5px 0 rgba(255, 255, 255, 0.25);
        ${backgroundStyle};
        color: white;
      `}
    >
      <div
        className={css`
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 8px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Tag
            color={'white'}
            style={{
              height: '24px',
            }}
          >
            <span
              className={css`
                color: ${fontColor};
                font-size: 12px;
                font-weight: bold;
              `}
            >
              {title}
            </span>
          </Tag>
          {active && (
            <Icon
              type="check-circle"
              theme="twoTone"
              twoToneColor="#52c41a"
              className={css`
                position: absolute;
                top: 19px;
                right: 19px;
                font-size: 30px;
              `}
            />
          )}
        </div>
        <h2
          className={css`
            margin-top: 12px;
            // text-align: center;
            color: white;
            font-weight: bold;
          `}
        >
          ${price} Per Month
        </h2>
      </div>
      <div
        className={css`
          padding: 24px;
        `}
      >
        {features.map((feature, i) => (
          <div
            key={i}
            className={css`
              font-size: 20x;
              font-weight: 600;
              margin-top: 5px;
              display: flex;
            `}
          >
            <Icon
              type="check-circle"
              className={css`
                margin-right: 8px;
                margin-top: 5px;
              `}
            />
            <div>{feature}</div>
          </div>
        ))}
      </div>
      <div
        className={css`
          flex: 1;
        `}
      />
      {onClick && (
        <button
          className={css`
            border-radius: 0 0 15px 15px;
            color: ${fontColor} !important;
            width: 100%;
            background-color: white;
          `}
          // onClick={() => updatePageState(STATES.SELECT_PLAN)}
          onClick={() => onClick()}
        >
          {actionText}
        </button>
      )}
    </div>
  </div>
)

export const PlanProCard = ({ actionText, onClick, active }) => (
  <PlanCard
    features={PRO_FEATURES}
    title="PRO"
    price="30"
    fontColor="#8b26e1"
    backgroundStyle={`
        background-image: linear-gradient(334deg, #d55ce8, #8b26e1);
      `}
    actionText={actionText}
    onClick={() => onClick()}
    active={active}
  />
)
export const PlanStandardCard = ({ actionText, onClick, active }) => (
  <PlanCard
    features={STANDARD_FEATURES}
    title="STANDARD"
    fontColor="#238de1"
    price="20"
    backgroundStyle={`
        background-image: linear-gradient(to top, #3ab9fc, #228de1);
      `}
    actionText={actionText}
    onClick={() => onClick()}
    active={active}
  />
)
export const PlanPlusCard = ({ actionText, onClick, active }) => (
  <PlanCard
    features={PLUS_FEATURES}
    title="PLUS"
    price="25"
    fontColor="#059599"
    backgroundStyle={`
        background-image: linear-gradient(to top, #22cba6, #008c97);
      `}
    actionText={actionText}
    onClick={() => onClick()}
    active={active}
  />
)
